import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const VacatingPage = () => {
  const navigate = useNavigate();
  const [vacatingHouses, setVacatingHouses] = useState([]);

  useEffect(() => {
    // Retrieve propertyId and token from local storage
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    // Fetch vacating houses using axios
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/properties/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setVacatingHouses(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching vacating houses:", error);
      });
  }, []);

  const handleCompleteVacating = (vacatingId) => {
    localStorage.setItem("selectedVacatingId", vacatingId);
    navigate(`/caretaker/updatevacating`);
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Vacating Houses</h1>
        <div>
          {vacatingHouses.map((house) => (
            <div key={house.id} className="border rounded p-4 my-2">
              <h2 className="text-xl font-semibold">{house.house_number}</h2>
              <p>Vacating Status: {house.vacatingstatus}</p>
              <p>
                Description of repairs and Quotations: {house.repaircharges}
              </p>
              <p>Total Amount Repairs: {house.totalamountrepairs}</p>
              <p>Complete Vacating: {house.completestatus}</p>
              <button
                onClick={() => handleCompleteVacating(house.vacatinghouse_id)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Complete Vacating
              </button>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default VacatingPage;
