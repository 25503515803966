import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const UpdateMaintenancePage = () => {
  const navigate = useNavigate();

  const handleInProgressClick = () => {
    const requestId = localStorage.getItem("selectedMaintenanceRequestId");
    const token = localStorage.getItem("token");

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/maintenance/maintenance-requests/${requestId}/in-progress`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Maintenance request marked as In Progress!");
        navigate(-1);
      })
      .catch((error) => {
        toast.success("Error updating maintenance request!");
        console.error("Error updating maintenance request:", error);
      });
  };

  const handleCompletedClick = () => {
    const requestId = localStorage.getItem("selectedMaintenanceRequestId");
    const token = localStorage.getItem("token");

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/maintenance/maintenance-requests/${requestId}/completed`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Maintenance request marked as Completed!");
        navigate(-1);
      })
      .catch((error) => {
        toast.error("Error updating maintenance request!");
        console.error("Error updating maintenance request:", error);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Update Maintenance</h1>
        <div className="mb-4">
          <button
            onClick={handleInProgressClick}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Mark as In Progress
          </button>
          <button
            onClick={handleCompletedClick}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Mark as Completed
          </button>
        </div>
      </div>
    </AuthGuard>
  );
};

export default UpdateMaintenancePage;
