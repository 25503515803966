import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUser,
  faBell,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const { selectedPropertyId } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [balance, setBalance] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(0);

  // Retrieve the JSON string from localStorage
  const userDataString = localStorage.getItem("user");

  // Parse the JSON string to convert it into a JavaScript object
  const userData = JSON.parse(userDataString);

  // Access the 'unique' property from the 'user' object
  const userId = userData.unique;

  const PropertyId = userData.propertyid;
  localStorage.setItem("selectedPropertyId", PropertyId);

  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fetch property details
    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/properties/records/${PropertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setProperty(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching property:", error);
        });

      // Fetch notifications count
      axios
        .get(
          "https://backend.inyumba.co.ke/iNyumba/inappnotifications/viewallnotifications",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setNotificationsCount(response.data.response.length);
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
          toast.error("Error fetching notifications");
        });
    }
  }, [token, PropertyId]);

  const handleVacating = () => {
    // Navigate to vacating page
    navigate(`/caretaker/vacating`);
  };

  const handleMaintenance = () => {
    // Navigate to maintenance page
    navigate(`/caretaker/maintenance`);
  };

  const handleReservations = () => {
    // Navigate to reservations page
    navigate(`/caretaker/reservations`);
  };

  const handleRentals = () => {
    // Navigate to rentals page
    navigate(`/caretaker/rentals`);
  };

  const handleProfileClick = () => {
    // Redirect to profile screen
    navigate("/caretaker/profile");
  };
  const handleNotificationsClick = () => {
    // Redirect to profile screen
    navigate("/caretaker/notifications");
  };

  const handleLogoutClick = () => {
    // Redirect to logout screen
    navigate("/logout");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold">My Properties</h1>
          <div className="flex items-center">
            <div
              className="relative cursor-pointer"
              onClick={handleNotificationsClick}
            >
              <FontAwesomeIcon icon={faBell} className="text-xl mr-4" />
              {notificationsCount > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                  {notificationsCount}
                </span>
              )}
            </div>
            <FontAwesomeIcon
              icon={faUser}
              className="text-xl mr-4 cursor-pointer"
              onClick={handleProfileClick}
            />
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="text-xl cursor-pointer"
              onClick={handleLogoutClick}
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          {/* Property details */}
          {property && (
            <div>
              <h1 className="text-3xl font-semibold mb-4">{property.title}</h1>
              <div className="mb-4">
                <img
                  src={property.photo}
                  alt={property.title}
                  className="w-full h-auto object-cover cursor-pointer"
                />
                {/* <GlassMagnifier imageSrc={property.photo} alt={property.title} /> */}
              </div>
              <p className="mb-2">
                <strong>Location:</strong> {property.location}
              </p>
              <p className="mb-2">
                <strong>County:</strong> {property.county}
              </p>
              <p className="mb-2">
                <strong>Description:</strong> {property.description}
              </p>
              <p className="mb-2">
                <strong>Approval Status:</strong> {property.approvalstatus}
              </p>
            </div>
          )}

          {/* Buttons */}
          <div className="flex flex-wrap justify-center mt-8">
            <button
              onClick={handleReservations}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
            >
              Reservations
            </button>
            <button
              onClick={handleMaintenance}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
            >
              Maintenance
            </button>
            <button
              onClick={handleVacating}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
            >
              Vacating
            </button>
            <button
              onClick={handleRentals}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
            >
              Rentals
            </button>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default Home;
