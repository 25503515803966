import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom"; // Import Navigate for redirection
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "./style.css"; // Import Tailwind CSS

import Login from "./Auth/Login";
import OtpVerification from "./Auth/OtpVerification";
import ForgotPassword from "./Auth/ForgotPassword";
import Logout from "./Auth/Logout";
import Home from "./Caretaker/home";
import Profile from "./Caretaker/profile";
import ReservationsPage from "./Caretaker/reservations";
import Rentals from "./Caretaker/rentals";
import SpecificRental from "./Caretaker/specificrental";
import DeletePhoto from "./Caretaker/deletephoto";
import DeleteVideo from "./Caretaker/deletevideo";
import MaintenancePage from "./Caretaker/maintenance";
import UpdateMaintenancePage from "./Caretaker/updatemaintenance";
import VacatingPage from "./Caretaker/vacating";
import UpdateVacatingPage from "./Caretaker/updatevacating";
import BillsPage from "./Caretaker/bills";
import { useEffect } from "react";
import NotificationsPage from "./Caretaker/notifications";

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes accessible whether user is logged in or not */}
        <Route path="/" element={<Login />} />
        <Route path="/OtpVerification" element={<OtpVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />

        {/* Routes accessible only when user is logged in */}

        <Route path="/caretaker/home" element={<Home />} />
        <Route path="/caretaker/profile" element={<Profile />} />
        <Route path="/caretaker/vacating" element={<VacatingPage />} />
        <Route
          path="/caretaker/updatevacating"
          element={<UpdateVacatingPage />}
        />
        <Route path="/caretaker/maintenance" element={<MaintenancePage />} />
        <Route
          path="/caretaker/updatemaintenance"
          element={<UpdateMaintenancePage />}
        />
        <Route path="/caretaker/reservations" element={<ReservationsPage />} />
        <Route path="/caretaker/rentals" element={<Rentals />} />
        <Route path="/caretaker/specificrental" element={<SpecificRental />} />
        <Route path="/caretaker/deletephoto" element={<DeletePhoto />} />
        <Route path="/caretaker/deletevideo" element={<DeleteVideo />} />
        <Route path="/caretaker/bills" element={<BillsPage />} />
        <Route
          path="/caretaker/notifications"
          element={<NotificationsPage />}
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

export function AuthGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const userDataString = localStorage.getItem("user");

    try {
      // Parse the JSON string to convert it into a JavaScript object
      const userData = JSON.parse(userDataString);
      // Access the 'unique' property from the 'user' object
      const userId = userData.unique;
      console.log(userId, "user");
      if (!userId) {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}

export function TokenGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}
