import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const Rentals = () => {
  const [rentals, setRentals] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/rentals/property/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Log the API response
          setRentals(response.data.response); // Set rentals state with response data
        })
        .catch((error) => {
          console.error("Error fetching rentals:", error);
        });
    } else {
      console.log("Missing propertyId or token in localStorage");
    }
  }, []); // Empty dependency array ensures useEffect runs only once after initial render

  const handleViewRental = (rentalId) => {
    localStorage.setItem("selectedRentalId", rentalId);
    navigate("/caretaker/specificrental");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Rentals</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {rentals.map((rental) => (
            <div key={rental.id} className="bg-white shadow-md rounded-md p-4">
              <p>
                <strong>Rent Price:</strong> {rental.rent_price}
              </p>
              <p>
                <strong>House Type:</strong> {rental.house_type}
              </p>
              <p>
                <strong>Description:</strong> {rental.description}
              </p>
              <p>
                <strong>House Number:</strong> {rental.house_number}
              </p>

              <button
                onClick={() => handleViewRental(rental.rental_id)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                View Rental
              </button>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default Rentals;
